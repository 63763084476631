@font-face {
  font-family: 'zocial';
  src: url("/assets/font/zocial.eot");
  src: url("/assets/font/zocial.eot?#iefix") format('embedded-opentype'), url("/assets/font/zocial.woff") format('woff'), url("/assets/font/zocial.ttf") format('truetype'), url("/assets/font/zocial.svg#zocial") format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="za-"]:before,
[class*=" za-"]:before {
  font-family: 'zocial';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  opacity: 0.8;
/* fix buttons height, for twitter bootstrap */
  line-height: 1em;
/* Animation center compensation - magrins should be symmetric */
/* remove if not needed */
  margin-left: 0.2em;
/* you can be more comfortable with increased icons size */
/* font-size: 120%; */
/* Uncomment for 3D effect */
/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.za-linkedin:before { content: '\4c'; } /* 'L' */
.za-rss:before { content: '\52'; } /* 'R' */
.za-twitter:before { content: '\54'; } /* 'T' */
.za-github:before { content: '\67'; } /* 'g' */
