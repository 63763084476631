// Import Skeleton
@import 'base';
@import 'layout';
@import 'skeleton';
@import 'zocial';

// Variables

$background_color : #f8f8f8;
$text_color       : #222;
$link_color       : #f64;

$fonts            : arial, "Calibri", helvetica, sans;
$fontsize         : 16px;

//$color1: #948799;
$color1: #C4D0D8;
$color2: #EF2F57;
//$color2: #62B347;
//$color2: #8CB347;

// CSS3 Mixins

@mixin border_radius($t:5px, $r:$t, $b:$t, $l:$t) {
  border-radius: $t $r $b $l;
}

@mixin box_shadow($h:2px, $v:2px, $blur:2px, $color:#999) {
  box-shadow: $h $v $blur $color;
}

@mixin animation-transform($transform) {
  transform: $transform;
}

@mixin keyframes($name, $start, $end) {
  @keyframes $name {
    0% { transform: $start; }
    100% { transform: $end; }
  }
}


// Main Styles

body {
  overflow-x: hidden;

  font: {
    family: $fonts;
    size: $fontsize;
  }
}

//a link
a {
  color: $color2;

  transition: color 0.2s;


  &:visited {
    color: #8833aa;
    //color: darken($color2, 20%);
    transition: color 0.2s;
  }

  &:hover {
    color: lighten($color2, 20%);
    transition: color 0.2s;
  }
}

h1 {
  a {
    text-decoration: none;
    color: rgb(51, 51, 51) !important;
  }
}

h4 {
  a {
    color: rgb(51, 51, 51);
  }
}

h3 {
  font-family: "Open Sans Condensed", $fonts;
  font-weight: normal;

  margin: 8px 0 8px 0;

  //color: #eee;
  font-size: 24px;
}

.center {
    text-align: center;
}

.experience {
    h3 {
        border-bottom: 1px solid #aaa;
    }

    div {
        font-style: italic;
        padding-bottom: 5px;
    }
    
    li {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

img {
  border: none;
  max-width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.pills {
    text-align: justify;

    .heading {
        font-weight: bold;
    }

    li {
        display: inline-block;
        background: #FFF7BC;
        padding: 6px 10px;
        border-radius: 1px;
        font-size: 0.85rem;
    }
}

#footer {
  padding-top: 20px;
  margin-bottom: 40px;
  color: #888;
  font-size: 0.8em;
  text-align: center;
}

.project {
    border: 1px solid #ddd;
    border-radius: 5px;
    
    padding: 10px;

    ul {
        margin-left: 0;
    }

    margin-bottom: 40px;
}

.multicolumn {
    column-count: 2;
    column-gap: 40px;

    @media only screen and (max-width: 959px) {
        column-count: 1;
    }
    
    &--keep {
        // HACK to keep the contents together
        display: inline-block;
        width: 100%;
    }
}

@keyframes wobble {
    0% { transform: translateX(-4px) rotate(-7deg); }
    100% { transform: translateX(4px) rotate(7deg); }
}

#profile {
    border-radius: 35px;
    float: left;
    margin: 5px;
    margin-right: 20px;
    position: relative;
    top: -1px;

    animation: wobble 3s alternate cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}

.page-break {
    page-break-after: always;
}

@media print {
    a {
        color: $color2 !important;
        text-decoration: none !important;
    }

    a.external-link:after {
        content: " (" attr(href) ") ";
    }
}
